import * as React from "react";
import styled from "styled-components";
import bnk from "../images/bnk.png";

const CustomHeader = styled.div`
  overflow: hidden;
  background-color: #f1f1f1;
  // padding: 10px 10px;

  a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
  }
`;

export default function Header() {
  return (
    <CustomHeader>
      <a href="/" class="logo">
        <img width={20} height={30} src={bnk} alt="bnk" />
      </a>
    </CustomHeader>
  );
}
