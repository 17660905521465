import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletList: [],
  walletListener: false
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletList: (state, action) => {
      state.walletList = action.payload;
    },
    setWalletListener: (state, action) => {
      state.walletListener = action.payload;
    }
  }
});

export const { setWalletList, setWalletListener } = walletSlice.actions;

export default walletSlice.reducer;
