import * as React from "react";
import _ from "lodash";
import CustomTabPanel from "../components/CustomTabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ImageList, ImageListItem } from "@mui/material";
import { a11yProps } from "../helpers";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import { LoadingButton } from "@mui/lab";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getNFT, getWallet, getCsvData } from "../services/nft";
import { useWalletList } from "../hooks/useWalletList";
import { useListOrderId } from "../hooks/useListOrderId";
import { useNft } from "../hooks/useNft";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

function Home() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [orderId, setOrderId] = React.useState("");
  const [walletId, setWalletId] = React.useState("");
  const [tokenId, setTokenId] = React.useState("");
  const [rarity, setRarity] = React.useState("All");
  const [showComponent, setShowComponent] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { wallet, setWallet } = useWalletList();
  const { listOrderId } = useListOrderId();
  const {
    nft,
    setNft,
    count,
    setCount,
    page,
    setPage,
    sortValue,
    setSortValue,
    sortOrderValue,
    setSortOrderValue,
    filter,
    setFilter,
    filterValue,
    setFilterValue
  } = useNft();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const handleSearch = async (e) => {
    const filterDataWallet = wallet.filter((res) =>
      res._id.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (e.target.value === "") {
      const response = await getWallet();
      if (response) {
        if (response.data.statusCode === "V:0001") {
          if (response.data.data) {
            const allWallet = response.data.data;
            setWallet(allWallet);
          }
        }
      }
    } else {
      setWallet(filterDataWallet);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowData = (value) => {
    setData(value);
    setOpen(true);
  };

  const getWalletId = React.useMemo(() => {
    if (wallet) {
      const data = wallet.map((res) => {
        return res._id;
      });
      return data;
    } else {
      return [];
    }
  }, [wallet]);

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrderValue(e.target.value);
  };

  const handleOrderChange = (_, v) => {
    setOrderId(v === null ? "" : v);
    setFilterValue(v === null ? "" : v);
  };

  const handleRarityChange = (e) => {
    setRarity(e.target.value);
    setFilterValue(e.target.value);
  };

  const handleWalletIdChange = (_, v) => {
    setWalletId(v === null ? "" : v);
    setFilterValue(v === null ? "" : v);
  };

  const handleTokenIdChange = (e) => {
    setTokenId(e.target.value);
    setFilterValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    setShowComponent(e.target.value);
    setRarity("");
    setOrderId("");
    setFilterValue("");
    setWalletId("");
    setFilter(e.target.value);
  };

  const handleSearchAll = async () => {
    setPage(1);
    const response = await getNFT(
      page,
      sortValue,
      sortOrderValue,
      filter,
      filter !== "trait.rarity"
        ? filterValue
        : filterValue === "All"
        ? ""
        : filterValue
    );
    if (response) {
      if (response.data.statusCode === "V:0001") {
        if (response.data.data) {
          const allNft = response.data.data.pop();
          setNft(allNft.data);
          if (_.isEmpty(allNft.metadata)) {
            setCount(0);
          } else {
            setCount(allNft.metadata[0]["total"]);
          }
        }
      }
    }
  };

  const clearFilter = () => {
    setShowComponent("");
    setFilter("");
    setFilterValue("");
    setTokenId("");
  };

  const downloadCsv = async () => {
    setLoading(true);
    const response = await getCsvData();
    if (response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `downloaded Report ${moment(new Date()).format(
        "DD MMM YY"
      )}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    }
  };

  return (
    <>
      <Container fixed sx={{ py: "20px" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={{ fontSize: 20 }} label="All NFT" {...a11yProps(0)} />
              <Tab sx={{ fontSize: 20 }} label="Wallet" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {value === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20
              }}
            >
              <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={() => downloadCsv()}
              >
                Download Csv
              </LoadingButton>
            </div>
          )}

          <CustomTabPanel value={value} index={0}>
            <Box component="section" sx={{ p: 2 }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                <Typography variant="h5"></Typography>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Sort
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleSortChange}
                        defaultValue={sortValue}
                      >
                        <FormControlLabel
                          value="wallet"
                          control={<Radio />}
                          label="Wallet Id"
                        />
                        <FormControlLabel
                          value="trait.metadata_id"
                          control={<Radio />}
                          label="Metadata Id"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Sort Order
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleSortOrderChange}
                        defaultValue={sortOrderValue}
                      >
                        <FormControlLabel
                          value="Desc"
                          control={<Radio />}
                          label="Desc"
                        />
                        <FormControlLabel
                          value="Asc"
                          control={<Radio />}
                          label="Asc"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Filter
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter}
                        defaultValue={filter}
                        label="filter"
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="order_id">Order Id</MenuItem>
                        <MenuItem value="trait.rarity">Rarity</MenuItem>
                        <MenuItem value="wallet">Wallet Id</MenuItem>
                        <MenuItem value="token_id">Token Id</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {showComponent === "order_id" && (
                    <Grid item xs={4}>
                      <Autocomplete
                        id="disabled-options-demo"
                        value={orderId}
                        options={listOrderId}
                        onChange={handleOrderChange}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Order id" />
                        )}
                      />
                    </Grid>
                  )}
                  {showComponent === "trait.rarity" && (
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Rarity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={rarity}
                          defaultValue={rarity}
                          label="Age"
                          onChange={handleRarityChange}
                        >
                          <MenuItem value="All">All</MenuItem>
                          <MenuItem value="Common">Common</MenuItem>
                          <MenuItem value="Rare">Rare</MenuItem>
                          <MenuItem value="Epic">Epic</MenuItem>
                          <MenuItem value="Legendary">Legendary</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {showComponent === "wallet" && (
                    <Grid item xs={4}>
                      <Autocomplete
                        id="disabled-options-demo"
                        value={walletId}
                        options={getWalletId}
                        onChange={handleWalletIdChange}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Wallet id" />
                        )}
                      />
                    </Grid>
                  )}
                  {showComponent === "token_id" && (
                    <Grid item xs={4}>
                      <Autocomplete
                        id="disabled-options-demo"
                        value={tokenId}
                        options={[]}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={tokenId}
                            label="Token id"
                            onChange={handleTokenIdChange}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={() => handleSearchAll()}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => clearFilter()}
                      sx={{ ml: 2 }}
                    >
                      Clear Filter
                    </Button>
                  </Grid>
                </Grid>
              </div>
              {nft ? (
                <ImageList
                  sx={{ width: "100%", height: 800 }}
                  cols={3}
                  rowHeight={350}
                >
                  {nft.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer"
                      }}
                      onClick={() => handleShowData(item)}
                    >
                      <ImageListItem key={item.token_id}>
                        <img
                          srcSet={item.trait.image}
                          src={item.trait.image}
                          alt={item.token_id}
                          loading="lazy"
                          width={120}
                          height={120}
                        />
                      </ImageListItem>
                    </div>
                  ))}
                </ImageList>
              ) : (
                <Typography variant="h5">No Data</Typography>
              )}
              {nft && (
                <Pagination
                  count={
                    Math.round(count / 12) === 0 ? 1 : Math.round(count / 12)
                  }
                  page={page}
                  color="primary"
                  onChange={handleChangePage}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                />
              )}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box component="section" sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Wallet Id"
                    id="fullWidth"
                    InputProps={{
                      type: "search"
                    }}
                    onChange={(e) => handleSearch(e)}
                  />
                </Grid>
              </Grid>
              <TableContainer component={Paper} sx={{ mt: 5 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Wallet Id
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }} align="right">
                        Total Nfts
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }} align="right">
                        Rarity
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wallet.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row._id}
                        </TableCell>
                        <TableCell align="right">{row.totalNFTs}</TableCell>
                        <TableCell align="right">
                          {row.rarities.map((v) => (
                            <Typography variant="subtitle1">
                              {v.rarity}: {v.count}
                            </Typography>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            href={`/detail?wallet=${row._id}`}
                          >
                            Detail
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CustomTabPanel>
        </Box>
      </Container>
      {open && !_.isEmpty(data) && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {!_.isEmpty(data) && (
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                  <img
                    srcSet={data.trait.image}
                    src={data.trait.image}
                    alt={data.token_id}
                    loading="lazy"
                    width="100%"
                    height="80%"
                  />
                </Grid>
                <Grid item xs={6}>
                  {Object.entries(data).map(([k, v], i) => (
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {k !== "trait" ? k : ""} {k !== "trait" ? ":" : ""}{" "}
                      {k !== "trait" ? v : ""}
                    </Typography>
                  ))}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Trait
                  </Typography>
                  {Object.entries(data.trait).map(([k, v], i) => (
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {k}: {v}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Box>
          )}
        </Modal>
      )}
    </>
  );
}

export default Home;
