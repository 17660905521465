import axios from "axios";
import _ from "lodash";

export async function getNFT(page, sort, sortBy, filter, value) {
  try {
    const [data] = await axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/bnk_nft_dashboard_backend/tf_get_wallet_inventory?page=${page}&sort=${sort}&sortBy=${sortBy}&filter=${filter}&value=${value}`
      )
      .then((data) => {
        return [data];
      })
      .catch((err) => [err]);
    return data;
  } catch (error) {
    return [];
  }
}

export async function getWallet(address) {
  try {
    const [data] = await axios
      .get(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/api/bnk_nft_dashboard_backend/tf_get_wallet_list${
          _.isEmpty(address) ? "" : `?address=${address}`
        }`
      )
      .then((data) => {
        return [data];
      })
      .catch((err) => [err]);
    return data;
  } catch (error) {
    return [];
  }
}

// https://bnk-nft-dashboard-backend-api-gw-gateway-7kl45r91.ts.gateway.dev/api/bnk_nft_dashboard_backend/tf_get_order_id_list

export async function getOrderIdService() {
  try {
    const [data] = await axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/bnk_nft_dashboard_backend/tf_get_order_id_list`
      )
      .then((data) => {
        return [data];
      })
      .catch((err) => [err]);
    return data;
  } catch (error) {
    return [];
  }
}

export async function getCsvData() {
  try {
    const [data] = await axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/bnk_nft_dashboard_backend/tf_get_nft_csv`
      )
      .then((data) => {
        return [data];
      })
      .catch((err) => [err]);
    return data;
  } catch (error) {
    return [];
  }
}
