import { useEffect, useState } from "react";
import { getOrderIdService } from "../services/nft";

export const useListOrderId = () => {
  const [listOrderId, setListOrderId] = useState([]);

  const fetchData = async () => {
    const response = await getOrderIdService();

    if (response) {
      if (response.data.statusCode === "V:0001") {
        if (response.data.data) {
          const allOrderId = response.data.data;
          const getValue = allOrderId.map((res) => {
            return res.order_id;
          });
          setListOrderId(getValue);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { listOrderId, setListOrderId };
};
