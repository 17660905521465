import { useState } from "react";
import _ from "lodash";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ImageList, ImageListItem } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { getNFT } from "../services/nft";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { useNft } from "../hooks/useNft";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

function Detail() {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const wallet = params.get("wallet");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const {
    nft,
    setNft,
    count,
    setCount,
    page,
    setPage,
    sortValue,
    setSortValue,
    sortOrderValue,
    setSortOrderValue
  } = useNft();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowData = (value) => {
    setData(value);
    setOpen(true);
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrderValue(e.target.value);
  };

  const handleSearchAll = async () => {
    setPage(1);
    const response = await getNFT(
      page,
      sortValue,
      sortOrderValue,
      "wallet",
      wallet
    );
    if (response) {
      if (response.data.statusCode === "V:0001") {
        if (response.data.data) {
          const allNft = response.data.data.pop();
          setNft(allNft.data);
          if (_.isEmpty(allNft.metadata)) {
            setCount(0);
          } else {
            setCount(allNft.metadata[0]["total"]);
          }
        }
      }
    }
  };

  return (
    <>
      <Container fixed sx={{ py: "20px" }}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Wallet Address: {wallet}
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Sort
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleSortChange}
                  defaultValue={sortValue}
                >
                  <FormControlLabel
                    value="wallet"
                    control={<Radio />}
                    label="Wallet Id"
                  />
                  <FormControlLabel
                    value="trait.metadata_id"
                    control={<Radio />}
                    label="Metadata Id"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Sort Order
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleSortOrderChange}
                  defaultValue={sortOrderValue}
                >
                  <FormControlLabel
                    value="Desc"
                    control={<Radio />}
                    label="Desc"
                  />
                  <FormControlLabel
                    value="Asc"
                    control={<Radio />}
                    label="Asc"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => handleSearchAll()}>
                Search
              </Button>
            </Grid>
          </Grid>
          <Box
            component="section"
            sx={{ p: 8, mt: 2, border: "1px dashed grey" }}
          >
            <ImageList
              sx={{ width: "100%", height: 800 }}
              cols={3}
              rowHeight={350}
            >
              {nft.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer"
                  }}
                  onClick={() => handleShowData(item)}
                >
                  <ImageListItem key={item.token_id}>
                    <img
                      srcSet={item.trait.image}
                      src={item.trait.image}
                      alt={item.token_id}
                      loading="lazy"
                      width={120}
                      height={120}
                    />
                  </ImageListItem>
                </div>
              ))}
            </ImageList>
            <Pagination
              count={Math.round(count / 12) === 0 ? 1 : Math.round(count / 12)}
              page={page}
              color="primary"
              onChange={handleChangePage}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            />
          </Box>
        </Box>
      </Container>
      {open && !_.isEmpty(data) && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {!_.isEmpty(data) && (
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                  <img
                    srcSet={data.trait.image}
                    src={data.trait.image}
                    alt={data.token_id}
                    loading="lazy"
                    width="100%"
                    height="80%"
                  />
                </Grid>
                <Grid item xs={6}>
                  {Object.entries(data).map(([k, v], i) => (
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {k !== "trait" ? k : ""} {k !== "trait" ? ":" : ""}{" "}
                      {k !== "trait" ? v : ""}
                    </Typography>
                  ))}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center" }}
                  >
                    Trait
                  </Typography>
                  {Object.entries(data.trait).map(([k, v], i) => (
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {k}: {v}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Box>
          )}
        </Modal>
      )}
    </>
  );
}
export default Detail;
