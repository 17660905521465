import { useEffect, useState } from "react";
import _ from "lodash";
import { getNFT } from "../services/nft";

export const useNft = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const wallet = params.get("wallet");
  const [nft, setNft] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sortValue, setSortValue] = useState("wallet");
  const [sortOrderValue, setSortOrderValue] = useState("Desc");
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const fetchData = async () => {
    const response = await getNFT(
      page,
      sortValue,
      sortOrderValue,
      wallet ? "wallet" : filter,
      wallet ? wallet : filterValue
    );

    if (response) {
      if (response.data.statusCode === "V:0001") {
        if (response.data.data) {
          const allNft = response.data.data.pop();
          setNft(allNft.data);
          if (_.isEmpty(allNft.metadata)) {
            setCount(0);
          } else {
            setCount(allNft.metadata[0]["total"]);
          }
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return {
    nft,
    setNft,
    count,
    setCount,
    page,
    setPage,
    sortValue,
    setSortValue,
    sortOrderValue,
    setSortOrderValue,
    filter,
    setFilter,
    filterValue,
    setFilterValue
  };
};
