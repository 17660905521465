import { useEffect, useState } from "react";
import { getWallet } from "../services/nft";

export const useWalletList = () => {
  const [wallet, setWallet] = useState([]);

  const fetchData = async () => {
    const response = await getWallet();

    if (response) {
      if (response.data.statusCode === "V:0001") {
        if (response.data.data) {
          const allWallet = response.data.data;
          setWallet(allWallet);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { wallet, setWallet };
};
